.capture{
    &__document-type{
        color: #7b7d7d;
      }

    &__uploadLogo{
        width:8%;
        margin-top: 1%;
    } 
    
    &__file{
       font-size: 14pt;
       margin-top: 3%;
       font-weight: 600;
    } 

    &__upload-file{
      

        margin: auto;
        width: 150px;
        height: 170px;
        border: 1px dashed $input-border; 
        border-radius: 5px;
        margin-top: 1rem;
        background-color: white;
        color:#7B7D7D;
        padding: 10px;
        position:relative
        }

    &__container-id-verification{
             
        margin: auto;
        width: 70%;
        height: 170px;
        border: 1px dashed $input-border; 
        border-radius: 5px;
        margin-top: 1rem;
        background-color: white;
        color:#7B7D7D;
        padding: 10px;
        position:relative;
        outline: none !important;
        }

    &__upload-file-img{
        width: 20%;
        margin-top: 30%;
    }

    &__icon-photo{
        width: 15%;
        margin-right: 3%;
    }
    &__icon-file{
        width: 15%;
        margin-right: 2%;
    }
    &__content_buttons{
        width: 100%;
        text-align: center;
        margin: 1rem 0;
        // display: none;
    }
    &__text-left{
        text-align: left;
    }
    &__title{
        font-size: 14pt;
        font-weight: 700;
        color: #4A4A68;
        margin-top: 6px;       
    }
    &__step{
        font-size: 11pt;
        font-weight: 600;
        color: #4A4A68;
    }
    &__number{
   
        color: white;
        border-radius: 50%;
        background-color: #54ba4c;       
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        padding-top: 1px;
        font-size: 10pt;
        display: inline-block;
    }

    &__container-instruction{
        background-color: white;
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-top: 6px;
    }

    &__div-container{
        width: 100%;
        height: 100%;
    }
 
    &__loading {
        position: absolute;
        /* left: 50%; */
        z-index: 1;
        color: var(--black);
        place-content: center;
        text-align: center;
        transform-origin: center;       
        border-radius: 10px 10px 0 0;
        // background-color: #bfbbc950;       
        width: 100%; 
        top: 40%;
       
    }

    &__label-document > img {
        width: 20px;
        margin: 0 auto;
        margin-right: 8px;
        margin-bottom: 3px;
        
      }

      &__wd-100 {
        width:100%;
      
      }

      &__relative {
         position: relative;      
      }

      &__label-document{
        background-color: #cecece;
        padding: 5px;
        font-weight: 700;
        border-radius: 5px;
        font-size: 14pt;   
      }
      &__primary-button{
        width: 40%;
       background-color: #045FA6;
      }
      &__img-selfie{
        width: 100px;
        margin-top: 20px;
        margin-bottom: 4px;
      }

      &__img-id-verification{
        margin-top: 10%;
      }
}

.capture__document-type{
.col-5{
    padding: 0px;
}
}