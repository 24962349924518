
$primary: #F16522;
$secondary: #045FA6;
$green: #58b950;
$secondary: #045FA6;
$input-border: #b1b1b1;

#root {
   text-align: left;
   width: 100%;
   background-color:#E1E9EE;
  //  max-width: 500px;
   min-height: 100vh;
  }

.App {
  text-align: center;
  background-color:#E1E9EE;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

  
}


.form-control:focus {
  color: #495057!important;
  background-color: #fff!important;
  border-color: #b1b1b1!important;
  outline: 0;
  box-shadow:none!important 
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none!important;
}

.main__submit{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}

.main__submit:hover{
   color: #fff!important;
   background-color: #045fa6!important;
   border-color: #045fa6!important;
   box-shadow:none!important 
}

.main__submit:focus{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}

.main__submit:active{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}

.capture__primary-button{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}

.capture__primary-button:hover{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}

.capture__primary-button:focus{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}


.capture__primary-button:active{
  color: #fff!important;
  background-color: #045fa6!important;
  border-color: #045fa6!important;
  box-shadow:none!important 
}



// .btn-secondary:hover {
//   color: #fff!important;
//   background-color:#045fa6!important ;
//   border-color: #045fa6!important;
// }

// .btn-success:hover {
//   color: #fff!important;
//   background-color: #045fa6!important;
//   border-color: #045fa6!important;
// }

// .btn-primary:hover {
//   color: #fff!important;
//   background-color: #045fa6!important;
//   border-color: #045fa6!important;
// }

// .btn-primary:active {
//   color: #fff!important;
//   background-color: #045fa6!important;
//   border-color: #045fa6!important;
// }



// .btn-primary:focus {
//   color: #fff!important;
//   background-color: #045fa6!important;
//   border-color: #045fa6!important;
//   box-shadow: none!important;
// }

// .btn-success:focus, .btn-success.focus {
//   color: #fff;
//   background-color: #045fa6!important;
//   border-color: #045fa6!important;
//   box-shadow:  none!important;
// }


// .btn-primary {
//   color: #fff!important;
//   background-color: #045fa6!important;
//   border-color: #045fa6!important;
// }



::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder { 
  text-transform: initial;
}

::-moz-placeholder {  
  text-transform: initial;
}

:-ms-input-placeholder { 
  text-transform: initial;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none;
}


@import '../node_modules/bootstrap/scss/bootstrap';
@import './Styles/components/viamobi.scss';
@import './Styles/components/menu.scss';
@import './Styles/components/logo.scss';
@import './Styles/components/deposit.scss';
@import './Styles/components/capture.scss';
@import './Styles/components/result.scss';