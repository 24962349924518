.deposit{
    &__fields{
        text-align: left;
        color:#7B7D7D
    }
    &__input{
        width: 100%;
        padding: 10px;
        border: 1px solid $input-border;
        border-radius: $border-radius;
        margin-bottom: 10px;
        font-size: 16px;
        text-transform: uppercase;
        color:#7B7D7D
    }

    &__phone{
        width: 100%;
        padding: 10px;
        border: 1px solid $input-border;
        border-radius: $border-radius;
        margin-bottom: 10px;
        font-size: 16px;       
        color:#7B7D7D
    }

    &__conditions-container{
        margin-left: 4%;
    }

   
}