.img-holder {
  margin: auto;
  width: 150px;
  height: 150px;
  border: 2px black solid;
  border-radius: 5px;
  margin-top: 1rem;
}

.img {
  width: 143px;
  height: 143px;
  object-fit: cover !important;
}

#input[type='file'] {
  display: none;
}

.inputBrach {
  text-transform: uppercase;
}

.button_l {
  width: 120px;
}

.input_width {
  width: 145px;
}
.icono_basura {
  position: absolute;
  right: 1px;
  :hover {
    cursor: pointer;
  }
}
.img_hover {
  :hover {
    cursor: pointer !important;
  }
}
.terms_cond {
  font-size: small;
  text-align: left;
}

.card {
  border-bottom: 1px solid silver !important;
}
.cardHeader {
  border: 0px;
  padding: 0;
}

.cardBotton {
  width: 100%;
  font-size: medium;
}

.iconBotton {
  float: right;
  margin-top: 3px;
}
.inputPhone {
  font-size: small;
  margin-bottom: 5px;
}
.cardBody {
  padding: 10px 10px 0px 10px;
}

.checkForm {
  :hover {
    cursor: pointer !important;
  }
  color: blue;
  text-decoration: underline;
}

p:hover {
  overflow: visible;
  white-space: normal;
  width: auto;
  background-color: #fff;
}

.modalTermsConditions {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  font-size: x-small;
  text-align: justify;
}

.btn-cancel {
  background-color: $blue;
  color: white;
}

.style-OL {
  margin-left: -16px !important;
}
li {
  margin-bottom: 5px;
}

.modal_title_add {
  margin-left: 60px;
}


.notPreview {
  margin-top: -39px;
  font-size: small;
  color: #f36421; //#28a745;
  //-webkit-text-stroke: 0.3px black;
  z-index: 1;
  position: relative;
  font-weight: bold;
  background: white;
  border-radius: 5px;
  //border: 1px solid black;
  opacity: 0.9;
}

.disclaimer {
  margin-top: 8px;
  font-size: small;
  color: gray; 
  z-index: 1;
  position: relative;
  font-weight: 600; 
  border-radius: 5px; 
  opacity: 0.9;
  display: inline-block;
}


.main{
  &__submit {
    background-color: $secondary;
  color: white;
  width: 100%;
  margin-top: 10px;
  }

  &__w-500{
    // width: 420px;
    max-width: 420px;
    // padding-left: 2px!important;
    // padding-right: 2px!important;  
  }
  
  &__no-margin{
      margin:  0px;
  }

  &__instruction{
    margin-bottom: 4px;
  }
}


.form-check{
  accent-color: #54ba4c;
}
