.result{
      &__image{
        width: 40%;
      }
      &__response-message{
       font-size: 16pt;
       color:#4A4A68 ;
       font-weight: 700;
      }

      &__container {
         margin-top: 4%;
         margin-bottom: 4%;
      }

    
    }
    