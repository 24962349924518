.menu{
  &__verification{
    color: #075ca6;
    font-weight: 700;    
  }

   &__container{
      border-radius: 20px;
      border-color: #5BB64D!important;
      border-style: solid;
      border-width: 1px;       
 
   }
   &__ComplianceContainer{
     border-radius: 20px;
     border-color: $primary!important;
     border-style: solid;
     border-width: 1px; 
   }
   &__item{
       border-radius: 20px;
       border-color: #5BB64D!important;
       background-color: #5BB64D!important;
       border-style: solid;
       border-width: 1px; 
       border-top-right-radius: 20px!important; 
       border-bottom-right-radius: 20px!important;
       z-index: 1000;
       color: #fff !important;   
     
    }

    &__item-compliance{
        width: 45%;  
   
     }

    &__item--disabled{       
     color: #7B7D7D!important;;
     background-color: transparent!important;;
     border: none!important;       
    
    }

    

    &__item-deposit{
     width: 60%;  

    }

    &__item-other{
     width: 40%;
     padding-left: 0px;
     padding-right: 0px;  

    }

    &__item:hover {
     border-radius: 20px;
     border-color: #5BB64D!important;
     background-color: #5BB64D!important;
     border-style: solid;
     border-width: 1px; 
     border-top-right-radius: 20px!important; 
     border-bottom-right-radius: 20px!important;
     z-index: 1000;  
    }

    &__item-disabled:hover {
     color: #7B7D7D;   
     background-color: transparent;
     border: none;
    }

    &__item-disabled:focus {
     color: #7B7D7D;   
     background-color: transparent;
     border: none;
     box-shadow: none;
 }

  &__item:focus {
     border-radius: 20px;
     border-color: #5BB64D!important;
     background-color: #5BB64D!important;
     border-style: solid;
     border-width: 1px; 
     border-top-right-radius: 20px!important; 
     border-bottom-right-radius: 20px!important;
     z-index: 1000;  
      box-shadow: none;
  }
  
  &__item-disabled:active {
     color: #7B7D7D;   
     background-color: transparent;
     border: none;
      box-shadow: none;
  }

  &__item:active {
     border-radius: 20px;
     border-color: #5BB64D!important;
     background-color: #5BB64D!important;
     border-style: solid;
     border-width: 1px; 
     border-top-right-radius: 20px!important; 
     border-bottom-right-radius: 20px!important;
     z-index: 1000;  
      box-shadow: none;
  }

  &__item:hover {
    border-radius: 20px;
    border-color: #5bb64d !important;
    background-color: #5bb64d !important;
    border-style: solid;
    border-width: 1px;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    z-index: 1000;
    box-shadow: none;
  }

  &__item:not(:disabled):not(.disabled).active:focus, &__item:not(:disabled):not(.disabled):active:focus, .show>&__item.dropdown-toggle:focus {
     border-radius: 20px;
     border-color: #5BB64D!important;
     background-color: #5BB64D!important;
     border-style: solid;
     border-width: 1px; 
     border-top-right-radius: 20px!important; 
     border-bottom-right-radius: 20px!important;
     z-index: 1000;  
     box-shadow: none
 }

 &__.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  border-radius: 20px;
  border-color: #5BB64D!important;
  background-color: #5BB64D!important;
  border-style: solid;
  border-width: 1px; 
  border-top-right-radius: 20px!important; 
  border-bottom-right-radius: 20px!important;
  z-index: 1000;  
  box-shadow: none
 }

 &__compliance-item{
  border-radius: 20px;
  border-color: $primary!important;
  background-color:  $primary!important;
  border-style: solid;
  border-width: 1px; 
  border-top-right-radius: 20px!important; 
  border-bottom-right-radius: 20px!important;
  z-index: 1000;
  width: 50%;
 }
 &__compliance-item-disabled {
  color: #7B7D7D;   
  background-color: transparent;
  border: none;
   box-shadow: none;
   width: 50%;
}

&__compliance-item:hover {
  border-radius: 20px;
  border-color: $primary!important;
  background-color: $primary!important;
  border-style: solid;
  border-width: 1px; 
  border-top-right-radius: 20px!important; 
  border-bottom-right-radius: 20px!important;
  z-index: 1000;  
 }

 &__compliance-item-disabled:hover {
  color: #7B7D7D;   
  background-color: transparent;
  border: none;
 }

 &__compliance-item-disabled:focus {
  color: #7B7D7D;   
  background-color: transparent;
  border: none;
  box-shadow: none;
}

&__compliance-item:focus {
  border-radius: 20px;
  border-color: $primary!important;
  background-color: $primary!important;
  border-style: solid;
  border-width: 1px; 
  border-top-right-radius: 20px!important; 
  border-bottom-right-radius: 20px!important;
  z-index: 1000;  
   box-shadow: none;
}

&__compliance-item-disabled:active {
  color: #7B7D7D;   
  background-color: transparent;
  border: none;
   box-shadow: none;
}

&__compliance-item:active {
  border-radius: 20px;
  border-color: $primary!important;
  background-color: $primary!important;
  border-style: solid;
  border-width: 1px; 
  border-top-right-radius: 20px!important; 
  border-bottom-right-radius: 20px!important;
  z-index: 1000;  
   box-shadow: none;
}

&__compliance-item:not(:disabled):not(.disabled).active:focus, &__item:not(:disabled):not(.disabled):active:focus, .show>&__item.dropdown-toggle:focus {
  border-radius: 20px;
  border-color: $primary!important;
  background-color: $primary!important;
  border-style: solid;
  border-width: 1px; 
  border-top-right-radius: 20px!important; 
  border-bottom-right-radius: 20px!important;
  z-index: 1000;  
  box-shadow: none
}

&__item:disabled {
  color: #7B7D7D!important;;
  background-color: transparent!important;;
  border: none!important;;
}

&____compliance-item:disabled {
  color: #7B7D7D!important;;
  background-color: transparent!important;;
  border: none!important;;
}

&__item.disabled, &__item:disabled {
  color: #7B7D7D!important;;
  background-color: transparent!important;;
  border: none!important;;
}

&__compliance-item-disabled:disabled {
  color: #7B7D7D!important;
  background-color: transparent!important;;
  border: none!important;;
}


}

.menu__compliance-item-disabled:not(:disabled):not(.disabled).active, .menu__compliance-item-disabled:not(:disabled):not(.disabled):active, .show>.menu__compliance-item-disabled.dropdown-toggle {
  color: #7B7D7D!important;
  background-color: transparent!important;
  border: none!important;;
}



.menu__compliance-item:not(:disabled):not(.disabled).active:focus, .menu__item:not(:disabled):not(.disabled):active:focus, .show > .menu__item.dropdown-toggle:focus {
  border-radius: 20px;
  border-color: #5bb64d !important;
  background-color: #5bb64d !important;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  z-index: 1000;
  box-shadow: none;
}